import _extends3 from "@babel/runtime/helpers/extends";
import _objectWithoutPropertiesLoose2 from "@babel/runtime/helpers/objectWithoutPropertiesLoose";
import _three from "three";
import _react from "react";
import _resizeObserverPolyfill from "resize-observer-polyfill";
import _construct2 from "@babel/runtime/helpers/construct";
import _reactReconciler from "react-reconciler";
import _scheduler from "scheduler";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}

var _extends = _interopDefault(_extends3);

var _objectWithoutPropertiesLoose = _interopDefault(_objectWithoutPropertiesLoose2);

var THREE = _three;
var React = _react;

var ResizeObserver = _interopDefault(_resizeObserverPolyfill);

var _construct = _interopDefault(_construct2);

var Reconciler = _interopDefault(_reactReconciler);

var scheduler = _scheduler;
var version = "2.4.3";

function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");

  return typeof key === "symbol" ? key : String(key);
}

function _toPrimitive(input, hint) {
  if (typeof input !== "object" || input === null) return input;
  var prim = input[Symbol.toPrimitive];

  if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (typeof res !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }

  return (hint === "string" ? String : Number)(input);
}

var roots = new Map();
var emptyObject = {};
var is = {
  obj: function obj(a) {
    return a === Object(a);
  },
  str: function str(a) {
    return typeof a === "string";
  },
  num: function num(a) {
    return typeof a === "number";
  },
  und: function und(a) {
    return a === void 0;
  },
  arr: function arr(a) {
    return Array.isArray(a);
  },
  equ: function equ(a, b) {
    // Wrong type, doesn't match
    if (typeof a !== typeof b) return false; // Atomic, just compare a against b

    if (is.str(a) || is.num(a) || is.obj(a)) return a === b; // Array, shallow compare first to see if it's a match

    if (is.arr(a) && a == b) return true; // Last resort, go through keys

    var i;

    for (i in a) {
      if (!(i in b)) return false;
    }

    for (i in b) {
      if (a[i] !== b[i]) return false;
    }

    return is.und(i) ? a === b : true;
  }
};
var globalEffects = [];

function addEffect(callback) {
  globalEffects.push(callback);
}

function renderGl(state, timestamp, repeat, runGlobalEffects) {
  if (repeat === void 0) {
    repeat = 0;
  }

  if (runGlobalEffects === void 0) {
    runGlobalEffects = false;
  } // Run global effects


  if (runGlobalEffects) globalEffects.forEach(function (effect) {
    return effect(timestamp) && repeat++;
  }); // Run local effects

  state.current.subscribers.forEach(function (fn) {
    return fn(state.current, timestamp);
  }); // Decrease frame count

  state.current.frames = Math.max(0, state.current.frames - 1);
  repeat += !state.current.invalidateFrameloop ? 1 : state.current.frames; // Render content

  if (!state.current.manual && state.current.gl) state.current.gl.render(state.current.scene, state.current.camera);
  return repeat;
}

var running = false;

function renderLoop(timestamp) {
  running = true;
  var repeat = 0; // Run global effects

  globalEffects.forEach(function (effect) {
    return effect(timestamp) && repeat++;
  });
  roots.forEach(function (root) {
    var state = root.containerInfo.__state; // If the frameloop is invalidated, do not run another frame

    if (state.current.active && state.current.ready && (!state.current.invalidateFrameloop || state.current.frames > 0)) repeat = renderGl(state, timestamp, repeat);
  });
  if (repeat !== 0) return requestAnimationFrame(renderLoop); // Flag end of operation

  running = false;
}

function invalidate(state, frames) {
  if (state === void 0) {
    state = true;
  }

  if (frames === void 0) {
    frames = 2;
  }

  if (state === true) roots.forEach(function (root) {
    return root.containerInfo.__state.current.frames = frames;
  });else if (state && state.current) {
    if (state.current.vr) return;
    state.current.frames = frames;
  }

  if (!running) {
    running = true;
    requestAnimationFrame(renderLoop);
  }
}

var catalogue = {};

var extend = function extend(objects) {
  return void (catalogue = _extends({}, catalogue, objects));
};

function applyProps(instance, newProps, oldProps, accumulative) {
  if (oldProps === void 0) {
    oldProps = {};
  }

  if (accumulative === void 0) {
    accumulative = false;
  } // Filter equals, events and reserved props


  var container = instance.__container;
  var sameProps = Object.keys(newProps).filter(function (key) {
    return is.equ(newProps[key], oldProps[key]);
  });
  var handlers = Object.keys(newProps).filter(function (key) {
    return typeof newProps[key] === "function" && key.startsWith("on");
  });
  var leftOvers = accumulative ? Object.keys(oldProps).filter(function (key) {
    return newProps[key] === void 0;
  }) : [];
  var filteredProps = [].concat(sameProps, ["children", "key", "ref"]).reduce(function (acc, prop) {
    var _ = acc[prop],
        rest = _objectWithoutPropertiesLoose(acc, [prop].map(_toPropertyKey));

    return rest;
  }, newProps); // Add left-overs as undefined props so they can be removed

  leftOvers.forEach(function (key) {
    return filteredProps[key] = undefined;
  });

  if (Object.keys(filteredProps).length > 0) {
    Object.entries(filteredProps).forEach(function (_ref) {
      var key = _ref[0],
          value = _ref[1];

      if (!handlers.includes(key)) {
        var root = instance;
        var target = root[key];

        if (key.includes("-")) {
          var entries = key.split("-");
          target = entries.reduce(function (acc, key) {
            return acc[key];
          }, instance); // If the target is atomic, it forces us to switch the root

          if (!(target && target.set)) {
            var _entries$reverse = entries.reverse(),
                _name = _entries$reverse[0],
                reverseEntries = _entries$reverse.slice(1);

            root = reverseEntries.reverse().reduce(function (acc, key) {
              return acc[key];
            }, instance);
            key = _name;
          }
        } // Special treatment for objects with support for set/copy


        if (target && target.set && target.copy) {
          var _target;

          if (target.constructor.name === value.constructor.name) target.copy(value);else if (Array.isArray(value)) (_target = target).set.apply(_target, value);else target.set(value); // Else, just overwrite the value
        } else root[key] = value;

        invalidateInstance(instance);
      }
    }); // Preemptively delete the instance from the containers interaction

    if (accumulative && container && instance.raycast && instance.__handlers) {
      instance.__handlers = undefined;

      var index = container.__interaction.indexOf(instance);

      if (index > -1) container.__interaction.splice(index, 1);
    } // Prep interaction handlers


    if (handlers.length) {
      // Add interactive object to central container
      if (container && instance.raycast) {
        // Unless the only onUpdate is the only event present we flag the instance as interactive
        if (!(handlers.length === 1 && handlers[0] === "onUpdate")) container.__interaction.push(instance);
      } // Add handlers to the instances handler-map


      instance.__handlers = handlers.reduce(function (acc, key) {
        var _extends2;

        return _extends({}, acc, (_extends2 = {}, _extends2[key.charAt(2).toLowerCase() + key.substr(3)] = newProps[key], _extends2));
      }, {});
    } // Call the update lifecycle when it is being updated, but only when it is part of the scene


    if (instance.parent) updateInstance(instance);
  }
}

function invalidateInstance(instance) {
  if (instance.__container && instance.__container.__state) invalidate(instance.__container.__state);
}

function updateInstance(instance) {
  if (instance.__handlers && instance.__handlers.update) instance.__handlers.update(instance);
}

function createInstance(type, _ref2, container) {
  var _ref2$args = _ref2.args,
      args = _ref2$args === void 0 ? [] : _ref2$args,
      props = _objectWithoutPropertiesLoose(_ref2, ["args"]);

  var name = "" + type[0].toUpperCase() + type.slice(1);
  var instance;

  if (type === "primitive") {
    instance = props.object;
    instance.__instance = true;
  } else {
    var target = catalogue[name] || THREE[name];
    instance = is.arr(args) ? _construct(target, args) : new target(args);
  } // Bind to the root container in case portals are being used
  // This is perhaps better for event management as we can keep them on a single instance


  while (container.__container) {
    container = container.__container;
  } // Apply initial props


  instance.__objects = [];
  instance.__container = container; // It should NOT call onUpdate on object instanciation, because it hasn't been added to the
  // view yet. If the callback relies on references for instance, they won't be ready yet, this is
  // why it passes "false" here

  applyProps(instance, props, {});
  return instance;
}

function appendChild(parentInstance, child) {
  if (child) {
    if (child.isObject3D) parentInstance.add(child);else {
      parentInstance.__objects.push(child);

      child.parent = parentInstance; // The attach attribute implies that the object attaches itself on the parent

      if (child.attach) parentInstance[child.attach] = child;else if (child.attachArray) {
        if (!is.arr(parentInstance[child.attachArray])) parentInstance[child.attachArray] = [];
        parentInstance[child.attachArray].push(child);
      } else if (child.attachObject) {
        if (!is.obj(parentInstance[child.attachObject[0]])) parentInstance[child.attachObject[0]] = {};
        parentInstance[child.attachObject[0]][child.attachObject[1]] = child;
      }
    }
    updateInstance(child);
    invalidateInstance(child);
  }
}

function insertBefore(parentInstance, child, beforeChild) {
  if (child) {
    if (child.isObject3D) {
      child.parent = parentInstance;
      child.dispatchEvent({
        type: "added"
      }); // TODO: the order is out of whack if data objects are present, has to be recalculated

      var index = parentInstance.children.indexOf(beforeChild);
      parentInstance.children = [].concat(parentInstance.children.slice(0, index), [child], parentInstance.children.slice(index));
      updateInstance(child);
    } else appendChild(parentInstance, child); // TODO: order!!!


    invalidateInstance(child);
  }
}

function removeRecursive(array, parent, clone) {
  if (clone === void 0) {
    clone = false;
  }

  if (array) {
    // Three uses splice op's internally we may have to shallow-clone the array in order to safely remove items
    var target = clone ? [].concat(array) : array;
    target.forEach(function (child) {
      return removeChild(parent, child);
    });
  }
}

function removeChild(parentInstance, child) {
  if (child) {
    if (child.isObject3D) {
      parentInstance.remove(child);
    } else {
      child.parent = null;
      parentInstance.__objects = parentInstance.__objects.filter(function (x) {
        return x !== child;
      }); // Remove attachment

      if (child.attach) parentInstance[child.attach] = null;else if (child.attachArray) parentInstance[child.attachArray] = parentInstance[child.attachArray].filter(function (x) {
        return x !== child;
      });else if (child.attachObject) {
        delete parentInstance[child.attachObject[0]][child.attachObject[1]];
      }
    }

    invalidateInstance(child);
    scheduler.unstable_runWithPriority(scheduler.unstable_IdlePriority, function () {
      // Remove interactivity
      if (child.__container) child.__container.__interaction = child.__container.__interaction.filter(function (x) {
        return x !== child;
      }); // Remove nested child objects

      removeRecursive(child.__objects, child);
      removeRecursive(child.children, child, true); // Dispose item

      if (child.dispose) child.dispose(); // Remove references

      delete child.__container;
      delete child.__objects;
    });
  }
}

function switchInstance(instance, type, newProps, fiber) {
  var parent = instance.parent;
  var newInstance = createInstance(type, newProps, instance.__container);
  removeChild(parent, instance);
  appendChild(parent, newInstance) // This evil hack switches the react-internal fiber node
  // https://github.com/facebook/react/issues/14983
  // https://github.com/facebook/react/pull/15021
  ;
  [fiber, fiber.alternate].forEach(function (fiber) {
    if (fiber !== null) {
      fiber.stateNode = newInstance;

      if (fiber.ref) {
        if (typeof fiber.ref === "function") fiber.ref(newInstance);else fiber.ref.current = newInstance;
      }
    }
  });
}

var Renderer = Reconciler({
  now: scheduler.unstable_now,
  createInstance: createInstance,
  removeChild: removeChild,
  appendChild: appendChild,
  insertBefore: insertBefore,
  supportsMutation: true,
  isPrimaryRenderer: false,
  // @ts-ignore
  schedulePassiveEffects: scheduler.unstable_scheduleCallback,
  cancelPassiveEffects: scheduler.unstable_cancelCallback,
  appendInitialChild: appendChild,
  appendChildToContainer: appendChild,
  removeChildFromContainer: removeChild,
  insertInContainerBefore: insertBefore,
  commitUpdate: function commitUpdate(instance, updatePayload, type, oldProps, newProps, fiber) {
    if (instance.__instance && newProps.object && newProps.object !== instance) {
      // <instance object={...} /> where the object reference has changed
      switchInstance(instance, type, newProps, fiber);
    } else if (instance.isObject3D) {
      // Common Threejs scene object
      applyProps(instance, newProps, oldProps, true);
    } else {
      // This is a data object, let's extract critical information about it
      var _newProps$args = newProps.args,
          argsNew = _newProps$args === void 0 ? [] : _newProps$args,
          restNew = _objectWithoutPropertiesLoose(newProps, ["args"]);

      var _oldProps$args = oldProps.args,
          argsOld = _oldProps$args === void 0 ? [] : _oldProps$args,
          restOld = _objectWithoutPropertiesLoose(oldProps, ["args"]); // If it has new props or arguments, then it needs to be re-instanciated


      var hasNewArgs = argsNew.some(function (value, index) {
        return is.obj(value) ? Object.entries(value).some(function (_ref3) {
          var key = _ref3[0],
              val = _ref3[1];
          return val !== argsOld[index][key];
        }) : value !== argsOld[index];
      });

      if (hasNewArgs) {
        // Next we create a new instance and append it again
        switchInstance(instance, type, newProps, fiber);
      } else {
        // Otherwise just overwrite props
        applyProps(instance, restNew, restOld, true);
      }
    }
  },
  hideInstance: function hideInstance(instance) {
    if (instance.isObject3D) {
      instance.visible = false;
      invalidateInstance(instance);
    }
  },
  unhideInstance: function unhideInstance(instance, props) {
    if (instance.isObject3D && props.visible == null || props.visible) {
      instance.visible = true;
      invalidateInstance(instance);
    }
  },
  getPublicInstance: function getPublicInstance(instance) {
    return instance;
  },
  getRootHostContext: function getRootHostContext() {
    return emptyObject;
  },
  getChildHostContext: function getChildHostContext() {
    return emptyObject;
  },
  createTextInstance: function createTextInstance() {},
  finalizeInitialChildren: function finalizeInitialChildren() {
    return false;
  },
  prepareUpdate: function prepareUpdate() {
    return emptyObject;
  },
  shouldDeprioritizeSubtree: function shouldDeprioritizeSubtree() {
    return false;
  },
  prepareForCommit: function prepareForCommit() {},
  resetAfterCommit: function resetAfterCommit() {},
  shouldSetTextContent: function shouldSetTextContent() {
    return false;
  }
});

function render(element, container, state) {
  var root = roots.get(container);

  if (!root) {
    container.__state = state;
    var newRoot = root = Renderer.createContainer(container, false, false);
    roots.set(container, newRoot);
  }

  Renderer.updateContainer(element, root, null, function () {
    return undefined;
  });
  return Renderer.getPublicRootInstance(root);
}

function unmountComponentAtNode(container) {
  var root = roots.get(container);
  if (root) Renderer.updateContainer(null, root, null, function () {
    return void roots["delete"](container);
  });
}

var hasSymbol = typeof Symbol === "function" && Symbol["for"];
var REACT_PORTAL_TYPE = hasSymbol ? Symbol["for"]("react.portal") : 60106;

function createPortal(children, containerInfo, implementation, key) {
  if (key === void 0) {
    key = null;
  }

  return {
    $$typeof: REACT_PORTAL_TYPE,
    key: key == null ? null : "" + key,
    children: children,
    containerInfo: containerInfo,
    implementation: implementation
  };
}

Renderer.injectIntoDevTools({
  bundleType: 0,
  version: version,
  rendererPackageName: "react-three-fiber",
  findHostInstanceByFiber: Renderer.findHostInstance
});

function isOrthographicCamera(def) {
  return def.isOrthographicCamera;
}

var stateContext = React.createContext({});

function useMeasure() {
  var ref = React.useRef(null);

  var _useState = React.useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0
  }),
      bounds = _useState[0],
      set = _useState[1];

  var _useState2 = React.useState(function () {
    return new ResizeObserver(function (_ref) {
      var entry = _ref[0];
      return set(entry.contentRect);
    });
  }),
      ro = _useState2[0];

  React.useEffect(function () {
    if (ref.current) ro.observe(ref.current);
    return function () {
      return ro.disconnect();
    };
  }, [ref.current]);
  return [ref, bounds];
}

function makeId(event) {
  return event.object.uuid + "/" + event.index;
}

var Canvas = React.memo(function (_ref2) {
  var children = _ref2.children,
      gl = _ref2.gl,
      camera = _ref2.camera,
      orthographic = _ref2.orthographic,
      raycaster = _ref2.raycaster,
      style = _ref2.style,
      pixelRatio = _ref2.pixelRatio,
      _ref2$vr = _ref2.vr,
      vr = _ref2$vr === void 0 ? false : _ref2$vr,
      _ref2$shadowMap = _ref2.shadowMap,
      shadowMap = _ref2$shadowMap === void 0 ? false : _ref2$shadowMap,
      _ref2$invalidateFrame = _ref2.invalidateFrameloop,
      invalidateFrameloop = _ref2$invalidateFrame === void 0 ? false : _ref2$invalidateFrame,
      _ref2$updateDefaultCa = _ref2.updateDefaultCamera,
      updateDefaultCamera = _ref2$updateDefaultCa === void 0 ? true : _ref2$updateDefaultCa,
      onCreated = _ref2.onCreated,
      onPointerMissed = _ref2.onPointerMissed,
      rest = _objectWithoutPropertiesLoose(_ref2, ["children", "gl", "camera", "orthographic", "raycaster", "style", "pixelRatio", "vr", "shadowMap", "invalidateFrameloop", "updateDefaultCamera", "onCreated", "onPointerMissed"]); // Local, reactive state


  var _useState3 = React.useState(false),
      ready = _useState3[0],
      setReady = _useState3[1];

  var _useMeasure = useMeasure(),
      bind = _useMeasure[0],
      size = _useMeasure[1];

  var _useState4 = React.useState(function () {
    return new THREE.Vector2();
  }),
      mouse = _useState4[0];

  var _useState5 = React.useState(function () {
    var element = document.createElementNS("http://www.w3.org/1999/xhtml", "canvas");
    element.style.display = "block";
    return element;
  }),
      defaultCanvas = _useState5[0];

  var _useState6 = React.useState(function () {
    var ray = new THREE.Raycaster();
    if (raycaster) applyProps(ray, raycaster, {});
    return ray;
  }),
      defaultRaycaster = _useState6[0];

  var _useState7 = React.useState(function () {
    var scene = new THREE.Scene();
    scene.__interaction = [];
    scene.__objects = [];
    return scene;
  }),
      defaultScene = _useState7[0];

  var _useState8 = React.useState(function () {
    var cam = orthographic ? new THREE.OrthographicCamera(0, 0, 0, 0, 0.1, 1000) : new THREE.PerspectiveCamera(75, 0, 0.1, 1000);
    cam.position.z = 5;
    if (camera) applyProps(cam, camera, {});
    return cam;
  }),
      defaultCam = _useState8[0],
      _setDefaultCamera = _useState8[1];

  var _useState9 = React.useState(function () {
    var renderer = new THREE.WebGLRenderer(_extends({
      canvas: defaultCanvas,
      antialias: true,
      alpha: true
    }, gl));
    renderer.setClearAlpha(0);

    if (shadowMap) {
      if (typeof shadowMap === "object") {
        renderer.shadowMap.enabled = true;
        Object.assign(renderer, shadowMap);
      } else {
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      }
    }

    return renderer;
  }),
      defaultRenderer = _useState9[0]; // Public state


  var state = React.useRef({
    ready: false,
    manual: false,
    vr: false,
    active: true,
    invalidateFrameloop: false,
    frames: 0,
    aspect: 0,
    subscribers: [],
    camera: defaultCam,
    scene: defaultScene,
    raycaster: defaultRaycaster,
    mouse: mouse,
    gl: defaultRenderer,
    canvas: defaultCanvas,
    captured: undefined,
    canvasRect: {
      bottom: 0,
      height: 0,
      width: 0,
      left: 0,
      right: 0,
      top: 0,
      x: 0,
      y: 0
    },
    size: {
      left: 0,
      top: 0,
      width: 0,
      height: 0
    },
    viewport: {
      width: 0,
      height: 0,
      factor: 0
    },
    initialClick: [0, 0],
    initialHits: [],
    subscribe: function subscribe(fn) {
      state.current.subscribers.push(fn);
      return function () {
        return state.current.subscribers = state.current.subscribers.filter(function (s) {
          return s !== fn;
        });
      };
    },
    setManual: function setManual(takeOverRenderloop) {
      state.current.manual = takeOverRenderloop;

      if (takeOverRenderloop) {
        // In manual mode items shouldn't really be part of the internal scene which has adverse effects
        // on the camera being unable to update without explicit calls to updateMatrixWorl()
        state.current.scene.children.forEach(function (child) {
          return state.current.scene.remove(child);
        });
      }
    },
    setDefaultCamera: function setDefaultCamera(camera) {
      return _setDefaultCamera(camera);
    },
    invalidate: function invalidate$1() {
      return invalidate(state);
    },
    intersect: function intersect(event) {
      return handlePointerMove(event || {});
    }
  }); // This is used as a clone of the current state, to be distributed through context and useThree

  var sharedState = React.useRef(state.current); // Writes locals into public state for distribution among subscribers, context, etc

  React.useLayoutEffect(function () {
    state.current.ready = ready;
    state.current.size = size;
    state.current.camera = defaultCam;
    state.current.invalidateFrameloop = invalidateFrameloop;
    state.current.vr = vr;
  }, [invalidateFrameloop, vr, ready, size, defaultCam]); // Manage canvas element in the dom

  React.useLayoutEffect(function () {
    // Add canvas to the view
    if (bind.current) bind.current.appendChild(defaultCanvas); // Start render-loop, either via RAF or setAnimationLoop for VR

    if (!state.current.vr) invalidate(state);else {
      defaultRenderer.vr.enabled = true;
      defaultRenderer.setAnimationLoop(function (t) {
        return renderGl(state, t, 0, true);
      });
    } // Dispose renderer on unmount

    return function () {
      if (defaultRenderer) {
        defaultRenderer.forceContextLoss();
        defaultRenderer.dispose();
      }

      state.current.gl = undefined;
      state.current.active = false;
      unmountComponentAtNode(state.current.scene);
    };
  }, []); // Update pixel ratio

  React.useLayoutEffect(function () {
    if (pixelRatio) defaultRenderer.setPixelRatio(pixelRatio);
  }, [pixelRatio]); // Adjusts default camera

  React.useLayoutEffect(function () {
    state.current.aspect = size.width / size.height || 0;

    if (isOrthographicCamera(state.current.camera)) {
      state.current.viewport = {
        width: size.width,
        height: size.height,
        factor: 1
      };
    } else {
      var target = new THREE.Vector3(0, 0, 0);
      var distance = state.current.camera.position.distanceTo(target);
      var fov = THREE.Math.degToRad(state.current.camera.fov); // convert vertical fov to radians

      var height = 2 * Math.tan(fov / 2) * distance; // visible height

      var width = height * state.current.aspect;
      state.current.viewport = {
        width: width,
        height: height,
        factor: size.width / width
      };
    } // Get canvas dom bounds


    if (bind.current) state.current.canvasRect = bind.current.getBoundingClientRect();

    if (ready) {
      defaultRenderer.setSize(size.width, size.height);
      /* https://github.com/drcmda/react-three-fiber/issues/92
         Sometimes automatic default camera adjustment isn't wanted behaviour */

      if (updateDefaultCamera) {
        if (isOrthographicCamera(state.current.camera)) {
          state.current.camera.left = size.width / -2;
          state.current.camera.right = size.width / 2;
          state.current.camera.top = size.height / 2;
          state.current.camera.bottom = size.height / -2;
        } else {
          state.current.camera.aspect = state.current.aspect; // TODO: Why radius??
          // state.current.camera.radius = (size.width + size.height) / 4
        }

        state.current.camera.updateProjectionMatrix();
      }

      invalidate(state);
    } // Only trigger the context provider when necessary


    sharedState.current = _extends({}, state.current);
  }, [ready, size, defaultCam, updateDefaultCamera]); // This component is a bridge into the three render context, when it gets rendererd
  // we know we are ready to compile shaders, call subscribers, etc

  var IsReady = React.useCallback(function () {
    var activate = React.useCallback(function () {
      return void (setReady(true), invalidate(state));
    }, []);
    React.useEffect(function () {
      if (onCreated) {
        var result = onCreated(state.current);
        if (result && result.then) return void result.then(activate);
      }

      activate();
    }, []);
    return null;
  }, []); // Render v-dom into scene

  React.useLayoutEffect(function () {
    if (size.width > 0 && size.height > 0) {
      render(React.createElement(stateContext.Provider, {
        value: sharedState.current
      }, React.createElement(IsReady, null), typeof children === "function" ? children(state.current) : children), state.current.scene, state);
    }
  });
  /** Sets up defaultRaycaster */

  var prepareRay = React.useCallback(function (event) {
    if (event.clientX !== void 0) {
      var canvasRect = state.current.canvasRect;
      var left = canvasRect && canvasRect.left || 0;
      var right = canvasRect && canvasRect.right || 0;
      var top = canvasRect && canvasRect.top || 0;
      var bottom = canvasRect && canvasRect.bottom || 0;
      var x = (event.clientX - left) / (right - left) * 2 - 1;
      var y = -((event.clientY - top) / (bottom - top)) * 2 + 1;
      mouse.set(x, y);
      defaultRaycaster.setFromCamera(mouse, state.current.camera);
    }
  }, []);
  /** Intersects interaction objects using the event input */

  var intersect = React.useCallback(function (event, prepare) {
    if (prepare === void 0) {
      prepare = true;
    }

    if (prepare) prepareRay(event);
    var seen = new Set();
    var hits = []; // Intersect known handler objects and filter against duplicates

    var intersects = defaultRaycaster.intersectObjects(state.current.scene.__interaction, true).filter(function (item) {
      var id = makeId(item);
      if (seen.has(id)) return false;
      seen.add(id);
      return true;
    });

    for (var _iterator = intersects, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();;) {
      var _ref3;

      if (_isArray) {
        if (_i >= _iterator.length) break;
        _ref3 = _iterator[_i++];
      } else {
        _i = _iterator.next();
        if (_i.done) break;
        _ref3 = _i.value;
      }

      var _intersect = _ref3;
      var receivingObject = _intersect.object;
      var object = _intersect.object; // Bubble event up

      while (object) {
        if (object.__handlers) hits.push(_extends({}, _intersect, {
          object: object,
          receivingObject: receivingObject
        }));
        object = object.parent;
      }
    }

    return hits;
  }, []);
  /**  Handles intersections by forwarding them to handlers */

  var handleIntersects = React.useCallback(function (event, fn) {
    prepareRay(event); // If the interaction is captured, take the last known hit instead of raycasting again

    var hits = state.current.captured && event.type !== "click" && event.type !== "wheel" ? state.current.captured : intersect(event, false);

    if (hits.length) {
      var unprojectedPoint = new THREE.Vector3(mouse.x, mouse.y, 0).unproject(state.current.camera);

      var _loop = function _loop() {
        if (_isArray2) {
          if (_i2 >= _iterator2.length) return "break";
          _ref4 = _iterator2[_i2++];
        } else {
          _i2 = _iterator2.next();
          if (_i2.done) return "break";
          _ref4 = _i2.value;
        }

        var hit = _ref4;
        var stopped = {
          current: false
        };
        fn(_extends({}, event, hit, {
          stopped: stopped,
          unprojectedPoint: unprojectedPoint,
          ray: defaultRaycaster.ray,
          // Hijack stopPropagation, which just sets a flag
          stopPropagation: function stopPropagation() {
            return stopped.current = true;
          },
          sourceEvent: event
        }));
        if (stopped.current === true) return "break";
      };

      for (var _iterator2 = hits, _isArray2 = Array.isArray(_iterator2), _i2 = 0, _iterator2 = _isArray2 ? _iterator2 : _iterator2[Symbol.iterator]();;) {
        var _ref4;

        var _ret = _loop();

        if (_ret === "break") break;
      }
    }

    return hits;
  }, []);
  var handlePointer = React.useCallback(function (name) {
    return function (event) {
      if (!state.current.ready) return; // Collect hits

      var hits = handleIntersects(event, function (data) {
        var object = data.object;
        var handlers = object.__handlers;

        if (handlers && handlers[name]) {
          // Forward all events back to their respective handlers with the exception of click,
          // which must must the initial target
          if (name !== "click" || state.current.initialHits.includes(object)) handlers[name](data);
        }
      }); // If a click yields no results, pass it back to the user as a miss

      if (name === "pointerDown") {
        state.current.initialClick = [event.clientX, event.clientY];
        state.current.initialHits = hits.map(function (hit) {
          return hit.object;
        });
      }

      if (name === "click" && !hits.length && onPointerMissed) {
        var dx = event.clientX - state.current.initialClick[0];
        var dy = event.clientY - state.current.initialClick[1];
        var distance = Math.round(Math.sqrt(dx * dx + dy * dy));
        if (distance <= 2) onPointerMissed();
      }
    };
  }, [onPointerMissed]);
  var hovered = new Map();
  var handlePointerMove = React.useCallback(function (event) {
    if (!state.current.ready) return;
    var hits = handleIntersects(event, function (data) {
      var object = data.object;
      var handlers = object.__handlers; // Check presence of handlers

      if (!handlers) return; // Call mouse move

      if (handlers.pointerMove) handlers.pointerMove(data); // Check if mouse enter or out is present

      if (handlers.pointerOver || handlers.pointerOut) {
        var id = makeId(data);
        var hoveredItem = hovered.get(id);

        if (!hoveredItem) {
          // If the object wasn't previously hovered, book it and call its handler
          hovered.set(id, data);
          if (handlers.pointerOver) handlers.pointerOver(_extends({}, data, {
            type: "pointerover"
          }));
        } else if (hoveredItem.stopped.current) {
          // If the object was previously hovered and stopped, we shouldn't allow other items to proceed
          data.stopPropagation(); // In fact, wwe can safely remove them from the cache

          Array.from(hovered.values()).forEach(function (data) {
            var checkId = makeId(data);

            if (checkId !== id) {
              if (data.object.__handlers.pointerOut) data.object.__handlers.pointerOut(_extends({}, data, {
                type: "pointerout"
              }));
              hovered["delete"](checkId);
            }
          });
        }
      }
    }); // Take care of unhover

    handlePointerCancel(event, hits);
    return hits;
  }, []);
  var handlePointerCancel = React.useCallback(function (event, hits) {
    if (!hits) hits = handleIntersects(event, function () {
      return null;
    });
    Array.from(hovered.values()).forEach(function (data) {
      if (hits && (!hits.length || !hits.find(function (i) {
        return i.object === data.object;
      }))) {
        var object = data.object;
        var handlers = object.__handlers;
        if (handlers && handlers.pointerOut) handlers.pointerOut(_extends({}, data, {
          type: "pointerout"
        }));
        hovered["delete"](makeId(data));
      }
    });
  }, []); // Render the canvas into the dom

  return React.createElement("div", _extends({
    ref: bind,
    onClick: handlePointer("click"),
    onWheel: handlePointer("wheel"),
    onPointerDown: handlePointer("pointerDown"),
    onPointerUp: handlePointer("pointerUp"),
    onPointerLeave: function onPointerLeave(event) {
      return handlePointerCancel(event, []);
    },
    onPointerMove: handlePointerMove // On capture intersect and remember the last known position
    ,
    onGotPointerCapture: function onGotPointerCapture(event) {
      return state.current.captured = intersect(event, false);
    } // On lost capture remove the captured hit
    ,
    onLostPointerCapture: function onLostPointerCapture(event) {
      return state.current.captured = undefined, handlePointerCancel(event);
    }
  }, rest, {
    style: _extends({
      position: "relative",
      width: "100%",
      height: "100%",
      overflow: "hidden"
    }, style)
  }));
});

function useRender(fn, takeOverRenderloop, deps) {
  if (takeOverRenderloop === void 0) {
    takeOverRenderloop = false;
  }

  if (deps === void 0) {
    deps = [];
  }

  var _useContext = React.useContext(stateContext),
      subscribe = _useContext.subscribe,
      setManual = _useContext.setManual; // This calls into the host to inform it whether the render-loop is manual or not


  React.useMemo(function () {
    return takeOverRenderloop && setManual(true);
  }, [takeOverRenderloop]);
  React.useEffect(function () {
    // Subscribe to the render-loop
    var unsubscribe = subscribe(fn);
    return function () {
      // Call subscription off on unmount
      unsubscribe();
      if (takeOverRenderloop) setManual(false);
    };
  }, deps);
}

function useThree() {
  var _useContext2 = React.useContext(stateContext),
      subscribe = _useContext2.subscribe,
      props = _objectWithoutPropertiesLoose(_useContext2, ["subscribe"]);

  return props;
}

function useUpdate(callback, dependents, optionalRef) {
  var _useContext3 = React.useContext(stateContext),
      invalidate = _useContext3.invalidate;

  var localRef = React.useRef();
  var ref = optionalRef ? optionalRef : localRef;
  React.useEffect(function () {
    if (ref.current) {
      callback(ref.current);
      invalidate();
    }
  }, dependents);
  return ref;
}

function useResource(optionalRef) {
  var _useState = React.useState(),
      resource = _useState[0],
      set = _useState[1];

  var localRef = React.useRef(undefined);
  var ref = optionalRef ? optionalRef : localRef;
  React.useEffect(function () {
    return void set(ref.current);
  }, [ref.current]);
  return [ref, resource];
}
/** experimental */


function useLoader(proto, url, extensions) {
  var key = React.useMemo(function () {
    return {};
  }, [url]);

  var _useState2 = React.useState(function () {
    return new WeakMap();
  }),
      cache = _useState2[0];

  var loader = React.useMemo(function () {
    var temp = new proto();
    if (extensions) extensions(temp);
    return temp;
  }, [proto]);

  var _useState3 = React.useState(false),
      _ = _useState3[0],
      forceUpdate = _useState3[1];

  React.useEffect(function () {
    if (!cache.has(key)) {
      loader.load(url, function (gltf) {
        var temp = [];
        gltf.scene.traverse(function (obj) {
          return obj.isMesh && temp.push({
            geometry: obj.geometry,
            material: obj.material
          });
        });
        cache.set(key, temp);
        forceUpdate(function (i) {
          return !i;
        });
      });
    }
  }, [proto, key]);
  return cache.get(key) || [];
}

var apply = function apply(objects) {
  console.warn("react-three-fiber: Please use extend \u2705 instead of apply \u274C, the former will be made obsolete soon!");
  extend(objects);
};

exports.Canvas = Canvas;
exports.addEffect = addEffect;
exports.apply = apply;
exports.applyProps = applyProps;
exports.createPortal = createPortal;
exports.extend = extend;
exports.invalidate = invalidate;
exports.isOrthographicCamera = isOrthographicCamera;
exports.render = render;
exports.stateContext = stateContext;
exports.unmountComponentAtNode = unmountComponentAtNode;
exports.useLoader = useLoader;
exports.useRender = useRender;
exports.useResource = useResource;
exports.useThree = useThree;
exports.useUpdate = useUpdate;
export default exports;
export const __esModule = exports.__esModule;
const _Canvas = exports.Canvas,
      _addEffect = exports.addEffect,
      _apply = exports.apply,
      _applyProps = exports.applyProps,
      _createPortal = exports.createPortal,
      _extend = exports.extend,
      _invalidate = exports.invalidate,
      _isOrthographicCamera = exports.isOrthographicCamera,
      _render = exports.render,
      _stateContext = exports.stateContext,
      _unmountComponentAtNode = exports.unmountComponentAtNode,
      _useLoader = exports.useLoader,
      _useRender = exports.useRender,
      _useResource = exports.useResource,
      _useThree = exports.useThree,
      _useUpdate = exports.useUpdate;
export { _Canvas as Canvas, _addEffect as addEffect, _apply as apply, _applyProps as applyProps, _createPortal as createPortal, _extend as extend, _invalidate as invalidate, _isOrthographicCamera as isOrthographicCamera, _render as render, _stateContext as stateContext, _unmountComponentAtNode as unmountComponentAtNode, _useLoader as useLoader, _useRender as useRender, _useResource as useResource, _useThree as useThree, _useUpdate as useUpdate };